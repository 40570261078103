@import 'colours';

.row-eq-height {
  display: flex;
  margin-bottom: 80px;
}

.home-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-picture {
  display: block; // required for centering
  margin: 0 auto;
  max-width: 500px;

  &.left {
    margin-left: 0;
  }

  &.right {
    margin-right: auto;
  }
}

@media (max-width: 1200px) {
  .home-picture {
    max-width: 400px;
  }
}

@media (max-width: 991px) {
  .row-eq-height {
    display: block; // responsive -> single column mode
    margin-bottom: 0;
  }

  .home-tile {
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .home-picture.left {
    margin-left: auto;
  }

  .home-picture.right {
    margin-right: auto;
  }

  .final-picture {
    display: none;
  }
}

@media (min-width: 768px) {
  .col-sm-6:first-child {
    padding-left: 0;
  }

  .col-sm-6:last-child {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .home-picture {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 430px;
    width: 96%;
  }
}

.mini-biog {
  color: $mini-biog-text-colour;

  h2 {
    font-size: 1.9em;
    font-weight: 300;
    letter-spacing: 0;
    text-align: left;
    text-transform: none;
  }

  p {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.4em;
    margin-bottom: 15px;

    b {
      font-weight: 300;
    }
  }
}

// Press quote
.press-tile {
  align-items: center;

}

.press {
  color: $press-quote-text-colour;
  font-size: 1.8em;
  position: relative; // for positioning the '"'
  width: 80%;

  &::before {
    color: $press-quote-speechmark-colour;
    content: '“';
    font-family: serif;
    font-size: 4em;
    left: -25px;
    position: absolute;
    top: -65px;
  }

  p {
    line-height: 1.1em;
    margin: 0 0 30px;
    text-align: center;
  }

  .attribution {
    color: $press-quote-attribution-text-colour;
    font-size: .95em;
    font-style: italic;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .press {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    max-width: 500px;
    position: relative;
    width: 80%;
  }
}
