@charset "UTF-8";
.row-eq-height {
  display: flex;
  margin-bottom: 80px;
}

.home-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-picture {
  display: block;
  margin: 0 auto;
  max-width: 500px;
}
.home-picture.left {
  margin-left: 0;
}
.home-picture.right {
  margin-right: auto;
}

@media (max-width: 1200px) {
  .home-picture {
    max-width: 400px;
  }
}
@media (max-width: 991px) {
  .row-eq-height {
    display: block;
    margin-bottom: 0;
  }
  .home-tile {
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .home-picture.left {
    margin-left: auto;
  }
  .home-picture.right {
    margin-right: auto;
  }
  .final-picture {
    display: none;
  }
}
@media (min-width: 768px) {
  .col-sm-6:first-child {
    padding-left: 0;
  }
  .col-sm-6:last-child {
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .home-picture {
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 430px;
    width: 96%;
  }
}
.mini-biog {
  color: rgb(60, 66, 62);
}
.mini-biog h2 {
  font-size: 1.9em;
  font-weight: 300;
  letter-spacing: 0;
  text-align: left;
  text-transform: none;
}
.mini-biog p {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.4em;
  margin-bottom: 15px;
}
.mini-biog p b {
  font-weight: 300;
}

.press-tile {
  align-items: center;
}

.press {
  color: rgb(70, 75, 70);
  font-size: 1.8em;
  position: relative;
  width: 80%;
}
.press::before {
  color: rgb(70, 70, 70);
  content: "“";
  font-family: serif;
  font-size: 4em;
  left: -25px;
  position: absolute;
  top: -65px;
}
.press p {
  line-height: 1.1em;
  margin: 0 0 30px;
  text-align: center;
}
.press .attribution {
  color: #000;
  font-size: 0.95em;
  font-style: italic;
  text-align: center;
}

@media (max-width: 992px) {
  .press {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    max-width: 500px;
    position: relative;
    width: 80%;
  }
}